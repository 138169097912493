<template>
<div>
	<v-row class="mt-n5">
		<!-- Start Date -->
			<v-col cols="3" sm="3" md="3">
				<v-menu
					ref="dialog_date_1"
					v-model="modal.date_1"
			        transition="scale-transition"
			        persistent
					width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
							v-model				="form.date_1"
							prepend-inner-icon	="event"
							background-color 	= "date"
							v-on				= "on"
							v-mask				="'####-##-##'"
							placeholder			= "YYYY-MM-DD"
							></v-text-field>
						</template>
						<v-date-picker v-model="form.date_1" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="modal.date_1 = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.dialog_date_1.save(form.date_1)">OK</v-btn>
						</v-date-picker>
					</v-menu>
			</v-col>
		<!-- End Date -->
			<v-col cols="3" sm="3" md="3">
				<v-menu
					ref="dialog_date_2"
					v-model="modal.date_2"
			        transition="scale-transition"
			        persistent
					width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
							v-model				="form.date_2"
							prepend-inner-icon	="event"
							background-color 	= "date"
							v-on				= "on"
							v-mask				="'####-##-##'"
							placeholder			= "YYYY-MM-DD"
							></v-text-field>
						</template>
						<v-date-picker v-model="form.date_2" :min = "form.date_1" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="modal.date_2 = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.dialog_date_2.save(form.date_1)">OK</v-btn>
						</v-date-picker>
					</v-menu>
			</v-col>
			<!-- Option PDF/Excel -->
			<v-col cols="12" sm="12" md="12">
				<v-btn-toggle
					v-model="form.output"
					color="secondary"
					group
					dense
				>
					<v-btn value="pdf" v-if="form.pdf">
					<v-icon>mdi-file-pdf-box</v-icon>PDF
					</v-btn>

					<v-btn value="excel" v-if="form.excel">
					<v-icon>mdi-file-excel-outline</v-icon>EXCEL
					</v-btn>
				
				</v-btn-toggle>
			</v-col>
	</v-row>
</div>
</template>
<script>
	export default {
	name:'rpt-clm-otd',
  		data: () => ({
			modal: {
				date_1: false,
				date_2: false,
			},
			form: {
				date_1: '',
				date_2: '',
				output: 'pdf',
				pdf: '',
				excel: '',
			}
		}),
	}
</script>